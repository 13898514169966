"use client";
import MetotGiCheckMark from "@/components/icon-center/MetotGiCheckMark";
import MetotHiOutlineExclamation from "@/components/icon-center/MetotHiOutlineExclamation";
import clsx from "clsx";
import useAppInfo from "../../modules/app/use-app-info";
import css from "./app-messenger.module.css";

export default function AppMessenger() {
  const messages = useAppInfo((state) => state.appMessages);
  function closeAtOnce(e) {
    e.currentTarget.style.display = "none";
  }
  return (
    <div className="fixed right-0 top-0 z-50 grid w-80 grid-cols-1 gap-3 p-2">
      {messages.map((m) => {
        switch (m.type) {
          case "success":
            return (
              <SuccessMessage
                key={m.id}
                onClose={closeAtOnce}
                message={m.message}
                duration={m.cssDuration}
              />
            );
          case "warning":
            return (
              <WarningMessage
                key={m.id}
                onClose={closeAtOnce}
                message={m.message}
                duration={m.cssDuration}
              />
            );
          case "error":
            return (
              <ErrorMessage
                key={m.id}
                onClose={closeAtOnce}
                message={m.message}
                duration={m.cssDuration}
              />
            );
        }
      })}
    </div>
  );
}

function ErrorMessage({ message, duration, onClose }) {
  return (
    <div
      style={{ "--duration": duration }}
      className={clsx(
        css.message,
        "flex items-center gap-3 rounded bg-red-100 px-4 py-3 text-red-600 shadow",
      )}
      onClick={onClose}
    >
      <span>
        <MetotHiOutlineExclamation size="1.8rem" />
      </span>
      <span className="font-semibold">{message}</span>
    </div>
  );
}

function SuccessMessage({ message, duration, onClose }) {
  return (
    <div
      style={{ "--duration": duration }}
      className={clsx(
        css.message,
        "flex items-center gap-3 rounded bg-green-800 px-4 py-3 text-green-100 shadow-lg",
      )}
      onClick={onClose}
    >
      <span>
        <MetotGiCheckMark size="1.5rem" />
      </span>
      <span className="font-semibold">{message}</span>
    </div>
  );
}

function WarningMessage({ message, duration, onClose }) {
  return (
    <div
      style={{ "--duration": duration }}
      className={clsx(
        css.message,
        "flex items-center gap-3 rounded bg-orange-100 px-4 py-3 text-orange-600 shadow",
      )}
      onClick={onClose}
    >
      <span>
        <MetotHiOutlineExclamation size="1.8rem" />
      </span>
      <span className="font-semibold">{message}</span>
    </div>
  );
}

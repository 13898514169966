import HttpRequest from "@/components/httpRequest";
import { validateEMail } from "@/components/utils";
import useAppInfo, { showErrorMessage } from "@/modules/app/use-app-info";
import useCompanyGroupListUnit from "@/modules/company-group/company-group-list-unit/use-company-group-list-unit";
import useCurrencyListUnit from "@/modules/currency/use-currency-list-unit";
import _isEmpty from "lodash/isEmpty";
import _uniqueId from "lodash/uniqueId";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";


const initialState = {
  reloadKey: 0,
  pageStatus: undefined,
  editorMode: undefined,
  company: {},
  groups: undefined,
  extraInfo: undefined,
  errors: {},
  validationWasMade: false,
};

const useCompanyUnit = create(immer((set, get) => ({
  ...initialState,

  changeCompanyInfo(values) {
    set((state) => {
      state.company = { ...state.company, ...values };
    });

    const { validate, validationWasMade } = get();
    if (validationWasMade) validate();
  },

  changeState(values) {
    set((state) => {
      return { ...state, ...values };
    });
  },

  validate() {
    const errors = findErrors(get().company);
    set((state) => {
      state.errors = errors;
      state.validationWasMade = true;
    });
    return _isEmpty(errors);
  },

  async initialize(companyId) {
    try {
      set((state) => {
        state.pageStatus = "loading";
        state.editorMode = isNaN(companyId);
      });

      await Promise.all([
        useCompanyGroupListUnit.getState().loadCompanyGroups(),
        useCurrencyListUnit.getState().loadCurrencies()
      ]);

      if (companyId) await loadCompany(companyId);
      else loadNew();

      set((state) => {
        state.pageStatus = "ready";
      });
    } catch (errorId) {
      set((state) => {
        state.pageStatus = errorId;
      });
    }

    function loadNew() {
      const companyGroups = useCompanyGroupListUnit.getState().companyGroups;
      const activeCurrencies =
        useCurrencyListUnit.getState().activeCurrencies;

      const groups = companyGroups
        .filter((g) => g.defaultValue)
        .map((g) => g.groupId);

      const balances = activeCurrencies.map((c) => ({
        currencyId: c.currencyId,
        currencyName: c.currencyName,
        balances: 0,
      }));

      set((state) => {
        state.company = {};
        state.groups = groups;
        state.extraInfo = generateExtraInfoRows(3);
        state.balances = balances;
        state.errors = {};
        state.validationWasMade = false;
      });
    }

    async function loadCompany(companyId) {
      const { company, groups, extraInfo, balances } = await getCompany(companyId);
      set((state) => {
        state.company = company;
        state.groups = groups;
        state.extraInfo = extraInfo;
        state.balances = balances;
      });
    }
  },

  reset() {
    set((state) => ({ ...state, ...initialState }));
  },

  reload() {
    set((state) => {
      state.editorMode = false;
      state.reloadKey++;
    });
  },

  setEditorMode(value) {
    set((state) => {
      state.editorMode = value;
    });
  },

  async createCompany() {
    try {
      set((state) => {
        state.pageStatus = "running";
      });

      const { company, groups, extraInfo, balances } = get();

      const newExtraInfo = extraInfo
        .filter((info) => info.label || info.value)
        .map((info, rowNumber) => ({
          ...info,
          infoId: undefined,
          rowNumber,
        }));

      const postData = {
        ...company,
        groups,
        extraInfo: newExtraInfo,
        openingBalances: balances,
      };

      await createCompany(postData);
      return true;

    } catch {
      showErrorMessage("Hata oluştu");
    } finally {
      set((state) => {
        state.pageStatus = "ready";
      });
    }
  },

  async updateCompany() {
    try {
      set((state) => {
        state.pageStatus = "running";
      });

      const { company, groups, extraInfo } = get();
      const payload = { ...company, groups, extraInfo };
      await updateCompany(payload);
      return true;

    } catch {
      showErrorMessage("Hata oluştu");
    } finally {
      set((state) => {
        state.pageStatus = "ready";
      });
    }
  },
})));

const REQUIRED_MESSAGE = "Zorunlu alan";

const findErrors = (company) => {
  const errors = {};
  if (!company.companyName) errors.companyName = REQUIRED_MESSAGE;
  if (company.eMail && !validateEMail(company.eMail))
    errors.eMail = "Geçersiz e-posta adresi";
  return errors;
};

export default useCompanyUnit;

function createCompany(company) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, "/metot/create-company")
    .addHeader("authorization", appToken)
    .postAsJson(company);
}

function updateCompany(company) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, "/metot/update-company")
    .addHeader("authorization", appToken)
    .putAsJson(company);
}

function getCompany(companyId) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, `/metot/get-company/${companyId}`)
    .addHeader("authorization", appToken)
    .get();
}

function generateExtraInfoRows(count) {
  const r = [];
  for (let i = 0; i < count; i++) {
    r.push({ infoId: _uniqueId("-"), label: "", value: "" });
  }
  return r;
}

